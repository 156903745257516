<template>
  <nav aria-label="Pagination Jobs">
    <ul class="pagination justify-content-center">
      <li :class="currentPage == 1 ? 'page-item disabled' : 'page-item'" aria-label="Previous">
        <a class="page-link" @click="updatePage({ page: currentPage-1 })">
          &laquo;
        </a>
      </li>
      <li v-for="(page, index) in totalPage" :key="index" :class="currentPage == page ? 'page-item active' : 'page-item'">
        <a class="page-link" @click="updatePage({ page })">{{ page }}</a>
      </li>
      <li :class="currentPage == totalPage ? 'page-item disabled' : 'page-item'">
        <a class="page-link" @click="updatePage({ page: currentPage+1 })" aria-label="Next">
          &raquo;
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: {
    totalData: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    totalPage: {
      type: Number,
      required: true
    },
  },
  methods: {
    updatePage({ page }) {
      this.$emit("update:page", parseInt(page, 10));
    },
  }
};
</script>

<style lang="scss" scoped>
.page-item .page-link,
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  color: #000;
  border-radius: 50%;
  background-color: #FFF;
  border-color: #4490d1;
  margin: 0 0.5rem;
  width: 40px;
  text-align: center;
}
.page-item.active .page-link,
.page-item .page-link:hover,
.page-item .page-link:focus {
  color: #FFF;;
  background-color: #4490d1;
  border-color: #4490d1;
}
li:hover {
  cursor: pointer;
}
</style>